var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

/* eslint no-debugger: 0*/

var notifications = document.querySelector('.notifications');

var Notification = function () {
  function Notification() {
    var _this = this;

    _classCallCheck(this, Notification);

    this.el = document.createElement('div');
    this.el.classList.add('notification');

    this.el.innerHTML = '<p class="notification__message"></p>';

    this.el.addEventListener('click', function () {
      return _this.destroy();
    });

    this.timeout = window.setTimeout(function () {
      return _this.destroy();
    }, 2000);

    this.el.addEventListener('transitionend', function () {
      _this.el.remove();
    });
  }

  _createClass(Notification, [{
    key: 'render',
    value: function render(text, style) {
      this.el.querySelector('.notification__message').innerText = text;

      if (style) {
        this.el.classList.add('notification--' + style);
      }
    }
  }, {
    key: 'destroy',
    value: function destroy() {
      window.clearTimeout(this.timeout);

      this.el.classList.toggle('notification--leaving', true);
    }
  }]);

  return Notification;
}();

export default function notify(text) {
  var style = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'info';

  var notification = new Notification();
  notification.render(text, style);

  notifications.appendChild(notification.el);
}