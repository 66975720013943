import notify from './notifications';
import firebase from './firebase';

var form = document.querySelector('.contact-form');

var user = void 0;
var contact = {};
var random = new Date().getMilliseconds();

firebase.auth().signInAnonymously();

firebase.auth().onAuthStateChanged(function (u) {
  user = u;
});

/* eslint no-debugger: 0 */

var stepHandlers = {
  0: function _() {
    var name = document.querySelector('[name=fullName]').value;
    var email = document.querySelector('[name=email]').value;
    var timestamp = new Date().toString();

    contact.name = name;
    contact.email = email;
    contact.timestamp = timestamp;

    return firebase.database().ref('contacts/' + user.uid + '-' + random).set(contact).then(function () {
      notify('Got it. We\'ll reach out soon!');
    }).then(function () {
      fetch(process.env.apiHost + '/contact', {
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(contact)
      });
    });
  },
  1: function _() {
    var interests = Array.from(document.querySelectorAll('[name=interest]:checked')).map(function (i) {
      return i.value;
    });
    var budget = document.querySelector('[name=budget]:checked');
    var message = document.querySelector('[name=message]').value;

    contact.interests = interests;
    contact.budget = budget;
    contact.message = message;

    return firebase.database().ref('contacts/' + user.uid + '-' + random).set(contact).then(function () {
      notify('Thanks for the extra info!');
    });
  }
};

if (form) {
  var parts = Array.from(document.querySelectorAll('[data-form-step]'));
  var currentIndex = 0;

  var showPart = function showPart() {
    var currentPart = parts[currentIndex];

    if (currentPart) {
      parts.forEach(function (p) {
        return p.classList.toggle('active', false);
      });

      currentPart.classList.toggle('active', true);
    }
  };

  showPart();

  form.addEventListener('submit', function (ev) {
    ev.preventDefault();

    Promise.resolve(stepHandlers[currentIndex]()).then(function () {
      currentIndex += 1;
      showPart();
    });
  });
}