import notify from './notifications';
import firebase from './firebase';

var form = document.querySelector('.training-form');
var nameInput = form && form.querySelector('[name=training-name]');
var emailInput = form && form.querySelector('[name=training-email]');
var companyInput = form && form.querySelector('[name=training-company]');
var venueInput = form && form.querySelector('[name=training-venue]');
var timestampInput = new Date().toString();
var random = new Date().getMilliseconds();

var user = void 0;

firebase.auth().signInAnonymously();

firebase.auth().onAuthStateChanged(function (u) {
  user = u;
});

var handleForm = function handleForm() {
  var values = {
    name: nameInput.value,
    email: emailInput.value,
    company: companyInput.value,
    venue: venueInput.value,
    timestamp: timestampInput
  };

  return firebase.database().ref('training-interests/' + user.uid + '-' + random).set(values).then(function () {
    fetch(process.env.apiHost + '/training-interests', {
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(values)
    });
  });
};

var hideForm = function hideForm() {
  form.style.display = 'none';
};

/* eslint no-debugger: 0 */
if (form) {
  form.addEventListener('submit', function (ev) {
    ev.preventDefault();

    Promise.resolve(handleForm()).then(function () {
      notify('Got it. We\'ll reach out soon!');

      hideForm();
    });
  });
}