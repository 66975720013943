var inputList = document.querySelectorAll('.btn--checkactive input');
var inputs = Array.from(inputList);

var checkActiveList = document.querySelectorAll('.btn--checkactive');
var btns = Array.from(checkActiveList);

function showStatus() {
  btns.forEach(function (btn) {
    var i = btn.querySelector('input');

    btn.classList.toggle('btn--primary-o', i.checked);
  });
}

inputs.forEach(function (i) {
  i.addEventListener('change', function () {
    showStatus();
  });
});